import firebase from 'firebase';

export default {
  storageRef(path) {
    return firebase.storage().ref(path);
  },

  async addNewAttachment(attachment, fileName, storage = 'Standard') {
    const operationalAttachment = this.storageRef(storage);

    const fileNameAdjusted = fileName.replace(/\//g, '_');
    const response = await operationalAttachment
      .child(fileNameAdjusted)
      .put(attachment);

    return response;
  },

  async getAttachments(storage = 'Standard') {
    const operationalAttachments = this.storageRef(storage);

    const response = await operationalAttachments.listAll();

    return response;
  },

  async getUserDocuments(storage, user) {
    const investorDocumentAttachs = this.storageRef(storage);

    const response = await investorDocumentAttachs.child(user).listAll();

    return response;
  },

  async deleteAttachment(file, storage = 'Standard') {
    const operationalAttachments = this.storageRef(storage);

    await operationalAttachments.child(file).delete();
  },

  async downloadAttachment(file, storage = 'Standard') {
    const operationalAttachments = this.storageRef(storage);

    operationalAttachments
      .child(file)
      .getDownloadURL()
      .then(function (url) {
        const xhr = new XMLHttpRequest();
        xhr.responseType = 'blob';
        xhr.onload = function () {
          const a = document.createElement('a');

          a.href = window.URL.createObjectURL(xhr.response);
          a.download = file;
          a.style.display = 'none';
          document.body.appendChild(a);
          a.click();
        };
        xhr.open('GET', url);
        xhr.send();
      });
  },

  async urlAttachment(file, storage = 'Standard') {
    let urlAtt = '';
    const operationalAttachments = this.storageRef(storage);
    await operationalAttachments
      .child(file)
      .getDownloadURL()
      .then(function (url) {
        urlAtt = url;
      });

    return urlAtt;
  },

  async addNewDocumentAttachment(attachment, fileName, storage, user) {
    const operationalAttachment = this.storageRef(storage);

    const fileNameAdjusted = fileName.replace(/\//g, '_');

    const response = await operationalAttachment
      .child(user)
      .child(fileNameAdjusted)
      .put(attachment);

    return response;
  },

  async addNewFinancingImageAttachment(
    attachment,
    fileName,
    storage,
    financingId,
  ) {
    const attach = this.storageRef(storage);

    const fileNameAdjusted = fileName.replace(/\//g, '_');

    const response = await attach
      .child(financingId)
      .child(fileNameAdjusted)
      .put(attachment);

    return response;
  },
};
