<script>
import 'quill/dist/quill.core.css';
import 'quill/dist/quill.snow.css';
import 'quill/dist/quill.bubble.css';
import 'vue-file-agent/dist/vue-file-agent.css';

import { cep } from '@/validate/customValidators.js';
import pessoasService from '@/services/Pessoas/pessoasService';
import statesService from '@/services/States/statesService';
import Card from '../../components/Cards/Card.vue';
import investorsService from '@/services/Investors/investorsService';
import attachmentsService from '@/services/Attachments/attachmentsService';
import logsService from '@/services/Logs/logsService';

import { vueTopprogress } from 'vue-top-progress';

import { quillEditor } from 'vue-quill-editor';

import {
  PessoaCaracteristica,
  CWLStatusInvestidor
} from '@/store/cwl_enums';

export default {
  components: { vueTopprogress, Card, quillEditor },
  data() {
    return {
      model: {
        states: [],
      },
      pessoa: {
        endereco: {
          estado: {}
        },
        dados_complementares: {
          dados_bancarios: {},
          perfil: {},
        },
      },
      isLoadingEnderecoPessoa: false,
      limite_investimento: '',
      stateSelected: {},
      submodalidade: process.env.VUE_APP_SUBTYPE,
      editorCuradoriaOption: {
        placeholder: 'Escreva aqui suas observações sobre a curadoria.',
      },
      selectedFilename: '',
      file: {
        item: {},
        name: 'Documento de Identificação'
      },
      fileChanged: false
    };
  },
  validations: {
    pessoa: {
      endereco: {
        cep: {
          valid: cep,
        },
      },
    },
  },
  async mounted() {
    await this.getStates();
    await this.getUserDocument();
    this.pessoa = this.$attrs.pessoa;
    this.setAddressStateSelected();

    this.model = this.$attrs.model || this.model;
    this.updateLimiteInvestimento();
  },
  computed: {
    investor() {
      return this.$store.getters.isLoggedIn;
    },
    currentPessoa() {
      return this.$store.getters.getPessoa;
    },
    role() {
      const value = this.$store.getters.userClaim
        ? this.$store.getters.userClaim.role
        : '';
      return value.toLowerCase();
    },
    isGestor() {
      return this.role == PessoaCaracteristica.Gestor;
    },
    isCurador() {
      return this.role == PessoaCaracteristica.Curador;
    },
    isGestorOrCurador() {
      return this.isCurador || this.isGestor;
    },
    isUsuario() {
      return this.role == PessoaCaracteristica.InvestorRole;
    },
    CWLStatusInvestidor() {
      return CWLStatusInvestidor;
    }
  },
  methods: {
    onEditorBlur(quill) {},
    onEditorFocus(quill) {},
    onEditorReady(quill) {},
    onCuradoriaEditorChange({ quill, html, text }) {
      this.pessoa.dados_complementares.approvalObservacao = html;
    },
    async reprovarCadastro() {
      this.$refs.topProgress.start();

      this.pessoa.dados_complementares.approvalStatus = CWLStatusInvestidor.Reprovado;

      try {
        await pessoasService.updatePessoaBy(this.pessoa, {
          email: this.pessoa.email.email,
        });
        await investorsService.enviarEmailAtualizacaoCadastroReprovado(this.pessoa);

        this.$notify({
          title: 'Investidor reprovado! Ele receberá um e-mail com esta informação.',
          icon: 'tim-icons icon-bell-55',
          horizontalAlign: 'center',
          verticalAlign: 'top',
          type: 'success',
          timeout: 3000,
        });

        this.$emit('cadastroReprovado');
      }
      catch (e) {
        console.log(e);
        this.$notify({
          title: 'Ooopss',
          message: 'Desculpe, ocorreu um erro. Tente novamente.',
          icon: 'tim-icons icon-bell-55',
          horizontalAlign: 'center',
          verticalAlign: 'top',
          type: 'warning',
          timeout: 3000,
        });
      }

      this.$refs.topProgress.done();
    },
    async aprovarCadastro() {
      this.$refs.topProgress.start();

      this.pessoa.dados_complementares.approvalStatus = CWLStatusInvestidor.Aprovado;

      try {
        await pessoasService.updatePessoaBy(this.pessoa, {
          email: this.pessoa.email.email,
        });
        await investorsService.enviarEmailAtualizacaoCadastroAprovado(this.pessoa);

        this.$notify({
          title: 'Investidor aprovado! Ele receberá um e-mail com esta informação.',
          icon: 'tim-icons icon-bell-55',
          horizontalAlign: 'center',
          verticalAlign: 'top',
          type: 'success',
          timeout: 3000,
        });

        this.$emit('cadastroAprovado');
      }
      catch (e) {
        console.log(e);
        this.$notify({
          title: 'Ooopss',
          message: 'Desculpe, ocorreu um erro. Tente novamente.',
          icon: 'tim-icons icon-bell-55',
          horizontalAlign: 'center',
          verticalAlign: 'top',
          type: 'warning',
          timeout: 3000,
        });
      }

      this.$refs.topProgress.done();
    },
    goToDocuments() {
      const pessoa = { ...this.pessoa };
      pessoa.endereco.estado = this.stateSelected;
      this.$emit('saveProfileInformations', { pessoa: pessoa, goToDocuments: true });
    },
    async saveProfileInformations() {
      await this.addNewAttachment();
    },
    setAddressStateSelected() {
      this.stateSelected = {};

      if (!this.pessoa.endereco) return;

      const estado = this.pessoa.endereco.estado;

      if (typeof estado == 'string') {
        for (let index = 0; index < this.model.states.length; index++) {
          const _state = this.model.states[index];
          if (_state.uf == estado) {
            this.stateSelected = _state;
            break;
          }
        }
      } else if (estado.uf) {
        this.stateSelected = estado;
      }
    },
    async getStates() {
      this.model.states = await statesService.getAllStates();
    },
    async changeCEP() {
      if (!this.$v.pessoa.endereco.cep.valid || this.isLoadingEnderecoPessoa)
        return;

      if (!this.isLoadingEnderecoPessoa) {
        this.isLoadingEnderecoPessoa = true;
        try {
          const enderecoFound = await pessoasService.searchAddressByZipcode(
            this.pessoa.endereco.cep,
          );
          this.pessoa.endereco = Object.assign(
            this.pessoa.endereco || {},
            enderecoFound || {},
          );
          this.setAddressStateSelected();
        } catch (error) {
          console.error(error);
        }
        this.isLoadingEnderecoPessoa = false;
      }
    },
    selectedFile() {
      this.selectedFilename = this.$refs.uploadFile.files[0].name;
      this.fileChanged = true;
    },
    clickFileInput() {
      this.$refs.uploadFile.click();
    },
    async getUserDocument() {
      if ( this.pessoaId ) {
        this.pessoa = await pessoasService.findPessoaById(this.pessoaId);
      }

      var email = this.currentPessoa.email.email;
      if ( this.pessoaId ) {
        email = this.pessoa.email.email;
      }
      const result = await attachmentsService.getUserDocuments(
        'InvestorDocuments',
        email,
      );

      result.items.map((item) => {
        if ( item.name == 'Documento de Identificação' ) {
          this.file.item = item;
          this.selectedFilename = item.name;
        }
      });
    },
    async downloadItem() {
      this.$refs.topProgress.start();

      const item = this.file.item;
      var email = this.currentPessoa.email.email;
      if ( this.pessoaId ) {
        email = this.pessoa.email.email;
      }

      try {
        const path = 'InvestorDocuments/' + email;
        await attachmentsService.downloadAttachment(item.name, path);
      } catch (e) {
        this.$notify({
          title: 'Ooopss',
          message: 'Desculpe, ocorreu um erro. Tente novamente.',
          icon: 'tim-icons icon-bell-55',
          horizontalAlign: 'center',
          verticalAlign: 'top',
          type: 'warning',
          timeout: 3000,
        });
      }

      this.$refs.topProgress.done();
    },
    async addNewAttachment() {
      this.$refs.topProgress.start();
      const file = await this.$refs.uploadFile.files[0];
      if ( !file ) {
        const pessoa = { ...this.pessoa };
        pessoa.endereco.estado = this.stateSelected;
        var documentFilled = false;
        if ( this.file.item.name ) {
          documentFilled = true;
        }
        this.$emit('saveProfileInformations', { pessoa: pessoa, goToDocuments: false, documentFilled: documentFilled });
        this.$refs.topProgress.done();
        return;
      }
      
      var email = this.currentPessoa.email.email;
      if ( this.pessoaId ) {
        let pessoa = await pessoasService.findPessoaById(this.pessoaId);
        email = pessoa.email.email;
      }

      try {
        const logUser = {
            user: {
                email: email
            }
        };
        logsService.createNewLog(logUser, '', 'Adicionar Documento Identificação', '', 'Documento de Identificação');
        await attachmentsService.addNewDocumentAttachment(file, 'Documento de Identificação', 'InvestorDocuments', email);

        const pessoa = { ...this.pessoa };
        pessoa.endereco.estado = this.stateSelected;
        this.$emit('saveProfileInformations', { pessoa: pessoa, goToDocuments: false, documentFilled: true });
      } catch (e) {
        console.log(e);
        this.$notify({
          title: 'Ooopss',
          message: 'Desculpe, ocorreu um erro. Tente novamente.',
          icon: 'tim-icons icon-bell-55',
          horizontalAlign: 'center',
          verticalAlign: 'top',
          type: 'warning',
          timeout: 3000,
        });
      }

      this.$refs.topProgress.done();
    },
    /* eslint-disable max-len */
    updateLimiteInvestimento() {
      const rendaBruta = this.pessoa.dados_complementares.perfil
        .renda_bruta_anual
        ? this.pessoa.dados_complementares.perfil.renda_bruta_anual
        : 0;
      const investimentosFinanceiros = this.pessoa.dados_complementares.perfil
        .investimentos_financeiros
        ? this.pessoa.dados_complementares.perfil.investimentos_financeiros
        : 0;
      const investimentosNestaPlataforma = this.pessoa.dados_complementares
        .perfil.investimentos_nesta_plataforma
        ? this.pessoa.dados_complementares.perfil.investimentos_nesta_plataforma
        : 0;
      const investimentosOutrasPlataformas = this.pessoa.dados_complementares
        .perfil.investimentos_outras_plataformas
        ? this.pessoa.dados_complementares.perfil
            .investimentos_outras_plataformas
        : 0;

      if (investimentosFinanceiros > 1000000) {
        this.limite_investimento = 'Ilimitado';
      } else if (investimentosFinanceiros > 100000 || rendaBruta > 100000) {
        let calculo10Porcento = 0;
        if (investimentosFinanceiros > rendaBruta) {
          calculo10Porcento = investimentosFinanceiros * 0.1;
        } else {
          calculo10Porcento = rendaBruta * 0.1;
        }
        const montante =
          investimentosNestaPlataforma + investimentosOutrasPlataformas;
        const limiteInvestimentoPuro = calculo10Porcento - montante;

        const limiteInvestimentoPreFormatado = parseFloat(
          limiteInvestimentoPuro,
        )
          .toFixed(2)
          .replace('.', ',');
        const limiteInvestimento =
          'R$ ' +
          limiteInvestimentoPreFormatado
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, '.');
        this.limite_investimento = limiteInvestimento;
      } else {
        const montante =
          investimentosNestaPlataforma + investimentosOutrasPlataformas;
        const limiteInvestimentoPuro = 10000 - montante;

        const limiteInvestimentoPreFormatado = parseFloat(
          limiteInvestimentoPuro,
        )
          .toFixed(2)
          .replace('.', ',');
        const limiteInvestimento =
          'R$ ' +
          limiteInvestimentoPreFormatado
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, '.');

        this.limite_investimento = limiteInvestimento;
      }
    },
  },
};
</script>

<template>
  <form @submit.prevent="saveProfileInformations()">
    <card>
      <h5 slot="header" class="title mt-3">Dados Complementares</h5>
      <div class="row">
        <div class="col-md-4 pr-md-1">
          <base-input
            v-model="pessoa.dados_complementares.rg"
            label="RG"
            :boldLabel="true"
            placeholder="Informe seu RG"
            required
          ></base-input>
        </div>
        <div class="col-md-4 pr-md-1">
          <base-input label="Estado Civil" style="font-weight: bold;">
            <select
              class="form-control"
              v-model="pessoa.dados_complementares.estado_civil"
              required
            >
              <option value="">Selecionar</option>
              <option value="solteiro">Solteiro(a)</option>
              <option value="casado">Casado(a)</option>
              <option value="divorciado">Divorciado(a)</option>
              <option value="viuvo">Viúvo(a)</option>
              <option value="separado_judicialmente">
                Separado(a) Judicialmente
              </option>
              <option value="outro">Outro</option>
            </select>
          </base-input>
        </div>
        <div class="col-md-4 pl-md-1">
          <base-input label="Genêro" :boldLabel="true">
            <select
              class="form-control"
              v-model="pessoa.dados_complementares.genero"
              required
            >
              <option value="">Selecionar</option>
              <option value="masculino">Masculino</option>
              <option value="feminino">Feminino</option>
              <option value="outro">Outro</option>
            </select>
          </base-input>
        </div>
      </div>

      <h5 class="title mt-3">Endereço</h5>
      <div class="row">
        <div class="col-md-12 pr-md-1">
          <div class="form-group">
            <base-input
              label="CEP"
              :boldLabel="true"
              v-mask="'#####-###'"
              placeholder="Digite seu CEP"
              v-model="pessoa.endereco.cep"
              v-on:input="changeCEP"
              required
            ></base-input>
            <label v-if="isLoadingEnderecoPessoa" style="cursor: default"
              ><i>Pesquisando...</i></label
            >
            <div
              class="error"
              v-if="pessoa.endereco.cep && !$v.pessoa.endereco.cep.valid"
            >
              Informe um CEP válido.
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-md-4 pr-md-1">
          <base-input
            label="Número"
            :boldLabel="true"
            placeholder="Digite seu Número"
            v-mask="['########']"
            v-model="pessoa.endereco.numero"
            required
          ></base-input>
        </div>
        <div class="col-md-4 pr-md-1">
          <base-input
            label="Complemento"
            :boldLabel="true"
            placeholder="Digite seu Complemento"
            v-model="pessoa.endereco.complemento"
          ></base-input>
        </div>
        <div class="col-md-4 pl-md-1">
          <base-input
            label="Bairro"
            :boldLabel="true"
            v-model="pessoa.endereco.bairro"
            placeholder="Digite seu Bairro"
            required
          ></base-input>
        </div>
      </div>

      <div class="row">
        <div class="col-md-4 pr-md-1">
          <base-input
            label="Rua"
            :boldLabel="true"
            v-model="pessoa.endereco.rua"
            placeholder="Digite sua Rua"
            required
          >
          </base-input>
        </div>
        <div class="col-md-4 pr-md-1">
          <base-input
            label="Cidade"
            :boldLabel="true"
            placeholder="Digite sua Cidade"
            v-model="pessoa.endereco.cidade"
            required
          ></base-input>
        </div>
        <div class="col-md-4 pl-md-1">
          <label style="font-weight: bold;">Estado</label>
          <select
            class="form-control"
            v-model="stateSelected" 
            required
          >
            <option
              :value="state"
              v-for="(state, index) in model.states"
              :key="index"
            >
              {{ state.name }}
            </option>
          </select>
        </div>
      </div>

      <h5 class="title mt-3">Profissão</h5>

      <div class="row">
        <div class="col-md-4 pr-md-1">
          <base-input
            label="Ocupação"
            :boldLabel="true"
            placeholder="Digite sua Ocupação"
            v-model="pessoa.dados_complementares.ocupacao"
            required
          ></base-input>
        </div>
        <div class="col-md-4 pr-md-1">
          <base-input
            label="Nome da Empresa (Opcional)"
            :boldLabel="true"
            placeholder="Digite o nome de sua Empresa"
            v-model="pessoa.dados_complementares.nome_empresa"
          ></base-input>
        </div>
        <div class="col-md-4 pl-md-1">
          <base-input
            label="Cargo (Opcional)"
            :boldLabel="true"
            placeholder="Digite seu Cargo"
            v-model="pessoa.dados_complementares.cargo"
          ></base-input>
        </div>
      </div>

      <h5 class="title mt-3">Dados Bancários</h5>

      <div class="row">
        <div class="col-md-4 pr-md-1">
          <base-input
            label="Agência sem dígito"
            :boldLabel="true"
            placeholder="Digite sua Agência"
            v-model="pessoa.dados_complementares.dados_bancarios.agencia"
            required
          ></base-input>
        </div>
        <div class="col-md-4 pr-md-1">
          <base-input
            label="Conta com dígito"
            :boldLabel="true"
            placeholder="Digite sua Conta"
            v-model="pessoa.dados_complementares.dados_bancarios.conta"
            required
          ></base-input>
        </div>
        <div class="col-md-4 pl-md-1">
          <base-input label="Banco" :boldLabel="true" required>
            <select
              class="form-control"
              v-model="pessoa.dados_complementares.dados_bancarios.banco"
              required
            >
              <option value="" disabled>Selecionar</option>
              <option
                v-for="bank in model.banks"
                :key="bank.code"
                :value="bank.name"
              >
                {{ bank.name }}
              </option>
            </select>
          </base-input>
        </div>
      </div>

      <h5 class="title mt-3">Perfil de Investimento</h5>

      <div class="row">
        <div class="col-md-12">
          <base-input
            required
            label="Qual situação melhor se encaixa ao seu perfil atual em relação a investimentos? (opcional)"
            :boldLabel="true"
          >
            <select
              class="form-control"
              v-model="pessoa.dados_complementares.perfil.perfil"
            >
              <option value="" disabled>Selecionar</option>
              <option
                v-for="profile in model.profiles"
                :key="profile.id"
                :value="profile.profile"
              >
                {{ profile.profile }}
              </option>
            </select>
          </base-input>
        </div>
      </div>

      <div class="row">
        <div class="col-md-12">
          <base-input
            label="Qual o principal segmento você tem interesse em investir? (opcional)"
            :boldLabel="true"
          >
            <select
              class="form-control"
              v-model="pessoa.dados_complementares.perfil.segmento"
            >
              <option value="" disabled>Selecionar</option>
              <option
                v-for="segment in model.segments"
                :key="segment.id"
                :value="segment.name"
              >
                {{ segment.name }}
              </option>
            </select>
          </base-input>
        </div>
      </div>

      <div class="row">
        <div class="col-md-12">
          <base-input
            label="Quanto do seu patrimônio você está disposto a investir? (opcional)"
            :boldLabel="true"
          >
            <select
              class="form-control"
              v-model="pessoa.dados_complementares.perfil.valor"
            >
              <option value="" disabled>Selecionar</option>
              <option
                v-for="value in model.values"
                :key="value.id"
                :value="value.values"
              >
                {{ value.values }}
              </option>
            </select>
          </base-input>
        </div>
      </div>

      <div class="row">
        <div class="col-md-12 mb-1">
          <base-checkbox
            v-model="pessoa.dados_complementares.aparecer_investidor"
          >
            Concordo em aparecer como investidor
          </base-checkbox>
        </div>
      </div>

      <div class="row">
        <div class="col-md-12 mb-3">
          <base-checkbox
            v-model="pessoa.dados_complementares.politicamente_exposto"
          >
            Sou uma pessoa politicamente exposta
          </base-checkbox>
        </div>
      </div>

      <div class="row" v-if="submodalidade == '588'">
        <div class="col-md-12">
          <label for="other-investments" style="font-weight: bold;">{{ $t('profile.otherInvestments') }}</label>
          <currency-input
            id="other-investments"
            class="form-control"
            v-model="
              pessoa.dados_complementares.perfil
                .investimentos_outras_plataformas
            "
            placeholder="Informe o valor dos Investimentos"
            currency="BRL"
            locale="pt-br"
            :auto-decimal-mode="true"
            :readonly="isGestorOrCurador"
          />
        </div>
      </div>

      <div class="row" v-if="submodalidade == '588'">
        <div class="col-md-12">
          <label for="investment-this-platform" style="font-weight: bold;">{{ $t('profile.investmentThisPlatform') }}</label>
          <currency-input
            readonly
            id="investment-this-platform"
            class="form-control"
            v-model="
              pessoa.dados_complementares.perfil.investimentos_nesta_plataforma
            "
            placeholder="R$ 0,00"
            currency="BRL"
            locale="pt-br"
            :auto-decimal-mode="true"
          />
        </div>
      </div>

      <div class="row">
        <div class="col-md-12">
          <label for="annual-gross" v-if="submodalidade == '588'" style="font-weight: bold;">{{ $t('profile.annualGross.588') }}</label>
          <label for="annual-gross" v-if="submodalidade == 'bacen'" style="font-weight: bold;">{{ $t('profile.annualGross.bacen') }}</label>

          <currency-input
            id="annual-gross"
            class="form-control"
            v-model="pessoa.dados_complementares.perfil.renda_bruta_anual"
            :placeholder="submodalidade == '588' ? $t('profile.annualGross.588') : $t('profile.annualGross.bacen')"
            currency="BRL"
            locale="pt-br"
            :auto-decimal-mode="true"
            v-on:focusout="updateLimiteInvestimento"
            required
          />
        </div>
      </div>

      <div class="row">
        <div class="col-md-12">
          <label for="financial-investments" v-if="submodalidade == '588'" style="font-weight: bold;">{{ $t('profile.financialInvestments.588') }}</label>
          <label for="financial-investments" v-if="submodalidade == 'bacen'" style="font-weight: bold;">{{ $t('profile.financialInvestments.bacen') }}</label>

          <currency-input
            id="financial-investments"
            class="form-control"
            v-model="pessoa.dados_complementares.perfil.investimentos_financeiros"
            :placeholder="submodalidade == '588' ? $t('profile.financialInvestments.588') : $t('profile.financialInvestments.bacen')"
            currency="BRL"
            locale="pt-br"
            :auto-decimal-mode="true"
            v-on:focusout="updateLimiteInvestimento"
            required
          />
        </div>
      </div>

      <div class="row" v-if="submodalidade == '588'">
        <div class="col-md-12">
          <base-input
            label="Limite Atual de Investimento"
            :boldLabel="true"
            placeholder=""
            v-model="limite_investimento"
            readonly
          ></base-input>
        </div>
      </div>

      <div class="row">
        <div class="col-md-6">
          <label class="form-label" style="font-weight: bold;">Documento de Identificação</label> <br>
          <div class="btn-group">
            <button type="button" class="btn btn-sm" v-if="file.item.name" @click="clickFileInput">
              Alterar
            </button>
            <button type="button" class="btn btn-sm btn-warning" v-if="!file.item.name" @click="clickFileInput">
              Selecionar documento obrigatório
            </button>
            <button type="button" class="btn btn-sm btn-dark" v-if="file.item.name" @click="downloadItem">
              Download
            </button>
            <span v-if="fileChanged" style="font-style: italic; font-size: 12px; padding-top: 5px;">Selecionado! Não esqueça de clicar em Salvar.</span>
          </div>
          <input style="opacity: 0; height: 0px; width: 0px; opacity:0; position:absolute; left: 0; top: 0;" class="form-control" type="file" ref="uploadFile" id="formFile" @change="selectedFile()" accept="image/*, .pdf" tabindex="-1">
        </div>
      </div>

      <div class="row">
        <div class="col-md-6">
          <button
            type="submit"
            slot="footer"
            class="btn btn-sm btn-primary mt-3"
            fill
          >
            Salvar
          </button>

          <!-- <button
            type="button"
            slot="footer"
            class="btn btn-sm btn-primary mt-3"
            @click="goToDocuments"
            fill
            v-if="isGestorOrCurador"
          >
            Documentos
          </button> -->

          <!-- <button
            type="button"
            slot="footer"
            class="btn btn-sm btn-primary mt-3"
            @click="goToDocuments"
            fill
            v-if="!isGestorOrCurador"
          >
            Meus Documentos
          </button> -->
        </div>
      </div>
    </card>
    
    <card v-if="isGestorOrCurador || pessoa.dados_complementares.approvalStatus == CWLStatusInvestidor.Reprovado">
      <h5 slot="header" class="title mt-3" v-if="isGestor">Gestor</h5>
      <h5 slot="header" class="title mt-3" v-if="isCurador">Curador</h5>
      <h5 slot="header" class="title mt-3" v-if="isUsuario">Curadoria</h5>

      <div class="row" v-if="isGestorOrCurador">
        <div class="col-md-12">
          <label for="quillEditor">Observações</label>
          <quill-editor
            ref="quillEditor"
            v-model="pessoa.dados_complementares.approvalObservacao"
            :options="editorCuradoriaOption"
            @blur="onEditorBlur($event)"
            @focus="onEditorFocus($event)"
            @ready="onEditorReady($event)"
            @change="onCuradoriaEditorChange($event)"
            :disabled="
              !isGestorOrCurador && pessoa.dados_complementares.approvalStatus == CWLStatusInvestidor.Reprovado
            "
          />
        </div>
      </div>

      <div class="row" v-if="isUsuario">
        <div class="col-md-12">
          <label
            class="control-label"
            style="cursor: default"
          >
            Seu cadastro foi analisado pela curadoria e não foi aprovado pelos motivos abaixo:
          </label>
        </div>
      </div>

      <div class="row" v-if="isUsuario">
        <div class="col-md-12">
          <div style="font-style: italic; font-size: 12px;" v-html="pessoa.dados_complementares.approvalObservacao"></div>
        </div>
      </div>

      <div class="row" v-if="isGestorOrCurador">
        <div class="col-md-6">
          <button
            type="button"
            slot="footer"
            class="btn btn-sm btn-primary mt-3"
            @click="aprovarCadastro"
            fill
          >
            Aprovar cadastro
          </button>
          <button
            type="button"
            slot="footer"
            class="btn btn-sm mt-3 btn-warning"
            @click="reprovarCadastro"
            fill
          >
            Reprovar cadastro
          </button>
        </div>
      </div>
    </card>
    <vue-topprogress ref="topProgress"></vue-topprogress>
  </form>
</template>
