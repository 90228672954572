<script>
import attachmentsService from '@/services/Attachments/attachmentsService';
import { CWLTipoPagamento } from '@/store/cwl_enums';
import moment from 'moment';

import { DoubleBounce } from 'vue-loading-spinner';

export default {
  name: 'financial-table',
  props: {
    columns: {
      type: Array,
      default: () => [],
      description: 'Table columns',
    },
    data: {
      type: Array,
      default: () => [],
      description: 'Table data',
    },
    type: {
      type: String,
      default: '',
      description: 'Whether table is striped or hover type',
    },
    theadClasses: {
      type: String,
      default: '',
      description: '<thead> css classes',
    },
    tbodyClasses: {
      type: String,
      default: '',
      description: '<tbody> css classes',
    },
  },
  components: {DoubleBounce},
  data() {
    return {
      page: 1,
      perPage: 1024,
      pages: [],
      CWLTipoPagamento: CWLTipoPagamento
    };
  },
  computed: {
    tableClass() {
      return this.type && `table-${this.type}`;
    },
    displayedData() {
      return this.paginate(this.data);
    },
  },
  methods: {
    hasValue(item, column) {
      return item[column.toLowerCase()] !== 'undefined';
    },
    itemValue(item, column) {
      return item[column.toLowerCase()];
    },
    setPages() {
      if (this.pages) {
        this.pages = [];
      }

      const numberOfPages = Math.ceil(this.data.length / this.perPage);

      for (let index = 1; index <= numberOfPages; index++) {
        this.pages.push(index);
      }
    },
    paginate(data) {
      const page = this.page;
      const perPage = this.perPage;

      const from = (page * perPage) - perPage;
      const to = (page * perPage);

      return data.slice(from, to);
    },
    formatDate(date) {
      return moment(date).tz('America/Sao_Paulo').format('LLL')
    },
    async downloadContratoCCB(item) {
      try {
        let filename = item.ccb.numero_ccb + "_ContratoCCB.pdf";
        await attachmentsService.downloadAttachment(filename, 'ContratosCCB');
      }
      catch (e) {
        console.log(e);
      }
    }
  },
  watch: {
    data() {
      this.setPages();
    },
  },
};
</script>

<template>
  <div>
    <table class="table tablesorter" :class="tableClass">
      <thead :class="theadClasses">
      <tr>
        <slot name="columns">
          <th v-for="column in columns" :key="column">{{column}}</th>
        </slot>
      </tr>
      </thead>
      <tbody :class="tbodyClasses">
        <tr v-for="(item, index) in displayedData" :key="index">
          <td>{{ (item.item.value * item.item.quantity) | currency }}</td>
          <td v-if="item.tipo_pagamento === CWLTipoPagamento.CartaoCredito">Cartão de Crédito</td>
          <td v-if="item.tipo_pagamento === CWLTipoPagamento.Boleto">Boleto</td>
          <td v-if="item.tipo_pagamento === CWLTipoPagamento.Reservado">Reservado</td>
          <td>{{ formatDate(item.order_date)  }}</td>
          <td>
            <i v-if="item.ccb && item.ccb.numero_ccb && item.ccb.possui_contrato_download" title="Download Contrato CCB" class="tim-icons icon-cloud-download-93" @click="downloadContratoCCB(item)"></i>
          </td>
        </tr>
      </tbody>
    </table>

    <nav class="table-pagination">
      <ul class="pagination">
        <li class="page-item">
          <button
            type="button"
            class="page-link"
            v-if="page != 1"
            @click="page--"
          >
            <i class="fas fa-angle-left"></i>
          </button>
        </li>

        <li class="page-item">
          <button
            type="button"
            class="page-link"
            v-for="(pageNumber, index) in pages.slice(page - 1, page + 5)"
            :key="index"
            @click="page = pageNumber"
            :class="{ 'is-active': page === pageNumber }"
          >
            {{ pageNumber }}
          </button>
        </li>

        <li class="page-item">
          <button
            type="button"
            class="page-link"
            v-if="page < pages.length"
            @click="page++"
          >
            <i class="fas fa-angle-right"></i>
          </button>
        </li>
      </ul>
    </nav>
  </div>
</template>
