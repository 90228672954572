<script>
import InvestorConfirmation from '@/pages/Profile/InvestorConfirmation';
import InvestorConfirmationUserCard from '@/pages/Profile/InvestorConfirmationUserCard';

import investorsService from '@/services/Investors/investorsService';

import UpdateInvestorStatusSuccessNotification from '@/components/Notifications/Investors/UpdateInvestorStatusSuccessNotification';
import UpdateInvestorStatusErrorNotification from '@/components/Notifications/Investors/UpdateInvestorStatusErrorNotification';

export default {
  components: {
    InvestorConfirmation,
    InvestorConfirmationUserCard,
    UpdateInvestorStatusSuccessNotification,
    UpdateInvestorStatusErrorNotification

  },
  data() {
    return {
      pessoa: {
        dados_complementares: {
          address: {},
          user: {},
          profile: {},
          bank: {},
        }
      },
      investorInformations: [],
    };
  },
  mounted() {
    this.getInvestorDetail();
  },
  computed: {
    investorId() {
      return this.$route.params.id;
    },
  },
  methods: {
    async getInvestorDetail() {
      const investorInformations = await investorsService.getDetail(this.investorId);

      const newInvestor = investorInformations.data();
      newInvestor.id = investorInformations.id;

      this.pessoa = newInvestor;
    },
    refuseInvestor() {
      this.updateUserStatus('Recusado');
    },
    approveInvestor() {
      this.updateUserStatus('Aprovado');
    },
    async updateUserStatus(status) {
      try {
        this.pessoa.dados_complementares.approvalStatus = status,

        // await investorsService.sendInvestorEmail(this.pessoa);

        await investorsService.updateInvestor(this.pessoa.id, this.pessoa);

        this.$notify({
          component: UpdateInvestorStatusSuccessNotification,
          icon: 'tim-icons icon-bell-55',
          horizontalAlign: 'center',
          verticalAlign: 'top',
          type: 'success',
          timeout: 3000,
        });

        this.$router.push('/investors');
      } catch (e) {
        this.$notify({
          component: UpdateInvestorStatusErrorNotification,
          icon: 'tim-icons icon-bell-55',
          horizontalAlign: 'center',
          verticalAlign: 'top',
          type: 'warning',
          timeout: 3000,
        });
      }
    },
  },
};
</script>

<template>
  <div class="row profile">
    <div class="col-md-8">
      <investor-confirmation
        :pessoa="pessoa"
        @refuseInvestor="refuseInvestor"
        @approveInvestor="approveInvestor"
      />
    </div>

    <div class="col-md-4">
      <investor-confirmation-user-card :pessoa="pessoa" />
    </div>
  </div>
</template>
