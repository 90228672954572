import Firebase from 'firebase/app';

const GoogleProvider = new Firebase.auth.GoogleAuthProvider();
const FacebookProvider = new Firebase.auth.FacebookAuthProvider();

export default {
  async loginWithCredentials(email, password) {
    return Firebase.auth().signInWithEmailAndPassword(email, password);
  },

  async createNewUser(email, password) {
    return await Firebase.auth().createUserWithEmailAndPassword(email, password);
  },

  async updateUser(user, newUser) {
    Firebase.auth().signInWithEmailAndPassword(user.email, user.password)
        .then(() => {
          Firebase.auth().currentUser.updateEmail(newUser.email);
        });
  },

  async deleteUser(user) {
    Firebase.auth().signInWithEmailAndPassword(user.email.email, user.dados_complementares.password)
        .then(() => {
          Firebase.auth().currentUser.delete();
        });
  },

  async loginWithGoogle() {
    return Firebase.auth().signInWithRedirect(GoogleProvider);
  },

  async loginWithFacebook() {
    return Firebase.auth().signInWithRedirect(FacebookProvider);
  },

  getRedirectResult() {
    return Firebase.auth().getRedirectResult();
  },

  async updatePassword(email) {
    return Firebase.auth().sendPasswordResetEmail(email);
  },
};
