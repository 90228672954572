<script>
import { DoubleBounce } from 'vue-loading-spinner';

import settingsService from '@/services/Settings/settingsService';
import partnerMapper from '@/services/Pessoas/mapper/partnerMapper';
import companyMapper from '@/services/Company/mapper/companyMapper';

import pessoasService from '@/services/Pessoas/pessoasService';
import socialTransformationService from '@/services/SocialTransformation/socialTransformationService';
import userBankAccountService from '@/services/Pessoas/userBankAccountService';
import AddressForm from '@/components/Address/AddressForm';
import CompanyIdentificationForm from '@/components/Company/CompanyIdentificationForm';
import CompanyPartnerConfirmationForm from '@/components/Company/CompanyPartnerConfirmationForm';
import BankAccountInformationForm from '@/components/Banks/BankAccountInformationForm';
import pessoaEnderecoService from '@/services/Pessoas/pessoaEnderecoService';
import { currentUserId } from '../../firebase';
import store from '@/store/index';

import { required } from 'vuelidate/lib/validators';

const BANK_ACCOUNT_INIT = {
  bank: {
    name: '',
  },
  agency: {
    number: '',
    digit: '',
  },
  account: {
    number: '',
    digit: '',
  },
};

const COMPANY_INIT = {
  identification: {},
  address: {},
  partner: {
    isPartner: false,
  },
  bankAccount: BANK_ACCOUNT_INIT,
};

export default {
  name: 'register-social-transformation-agent',
  data() {
    return {
      loading: true,
      value: {
        status: 'draft',
        address: {},
        company: COMPANY_INIT,
      },
    };
  },
  validations: {
    value: {
      address: {
        cep: {
          valid: required,
        },
        rua: {
          valid: required,
        },
        numero: {
          valid: required,
        },
        bairro: {
          valid: required,
        },
        estado: {
          valid: required,
        },
        cidade: {
          valid: required,
        },
      },
      company: {
        identification: {
          identificationNumber: {
            valid: required,
          },
          name: {
            valid: required,
          },
          displayName: {
            valid: required,
          },
        },
        address: {
          cep: {
            valid: required,
          },
          rua: {
            valid: required,
          },
          numero: {
            valid: required,
          },
          bairro: {
            valid: required,
          },
          estado: {
            valid: required,
          },
          cidade: {
            valid: required,
          },
        },
        partner: {
          isPartner: {
            valid: (value) => value === true,
          },
        },
        bankAccount: {
          bank: {
            name: {
              valid: required,
            },
          },
          agency: {
            number: {
              valid: required,
            },
            digit: {
              valid: required,
            },
          },
          account: {
            number: {
              valid: required,
            },
            digit: {
              valid: required,
            },
          },
        },
      },
    },
  },
  computed: {},
  components: {
    DoubleBounce,
    AddressForm,
    CompanyIdentificationForm,
    CompanyPartnerConfirmationForm,
    BankAccountInformationForm,
  },
  methods: {
    async handleAddress(address) {
      this.value.address = {
        ...this.value.address,
        ...address,
      };
      pessoaEnderecoService.saveCurrentUserAddress(this.value.address);
    },
    async handleCompanyIdentification(identification) {
      this.loading = true;
      try {
        console.debug('handle company changed', identification);

        if (identification && identification.identificationNumber) {
          const pessoa = store.getters.getPessoa;
          const empresa = await pessoasService.updateCompany(
            {
              documento: {
                numero: identification.identificationNumber,
              },
              nome: identification.name,
              dados_complementares: {
                nome_fantasia: identification.displayName,
              },
            },
            {
              responsavel: pessoa,
            },
          );
          pessoasService.updateCompanyByUserId(
            { id: empresa.id },
            currentUserId(),
          );
          await this.fromEmpresa(empresa);
          console.debug('company addded', this.value.company);
        } else {
          pessoasService.deleteCompanyByUserId(currentUserId());
          this.value.company = COMPANY_INIT;
          console.debug('company removed', this.value.company);
        }
      } catch (error) {
        console.error('handle company error', error);
      } finally {
        this.loading = false;
        this.$forceUpdate();
      }
    },
    async handleCompanyAddress(address) {
      this.value.company.address = {
        ...this.value.company.address,
        ...address,
      };
      pessoaEnderecoService.saveAddressByUserId(
        this.value.company.address,
        this.value.company.id,
      );
    },
    async handleCompanyPartner(partner) {
      const { isPartner } = partner;
      this.value = {
        ...this.value,
        company: {
          ...this.value.company,
          partner: {
            ...this.value.company.partner,
            isPartner,
          },
        },
      };
      pessoasService.updateCompanyByUserId(
        {
          isPartner: partner.isPartner,
        },
        currentUserId(),
      );
    },
    async handleCompanyBankAccount(bankAccount) {
      this.value.company.bankAccount = {
        ...this.value.company.bankAccount,
        ...bankAccount,
      };

      const value = bankAccount
        ? {
            banco:
              bankAccount.bank && bankAccount.bank.name
                ? bankAccount.bank.name
                : '',
            agencia:
              bankAccount.agency && bankAccount.agency.number
                ? bankAccount.agency.number +
                  (bankAccount.agency.digit
                    ? '-' + bankAccount.agency.digit
                    : '')
                : '',
            conta:
              bankAccount.account && bankAccount.account.number
                ? bankAccount.account.number +
                  (bankAccount.account.digit
                    ? '-' + bankAccount.account.digit
                    : '')
                : '',
          }
        : {};
      userBankAccountService.saveByUserId(value, this.value.company.id);
    },
    async send() {
      try {
        this.loading = true;
        const nextStatus = 'review_requested';
        await socialTransformationService.updateStatusByUserId(
          nextStatus,
          currentUserId(),
        );
        this.value.status = nextStatus;
      } catch (error) {
        console.error('send register social transformation error', error);
      } finally {
        this.loading = false;
      }
    },
    async fromEmpresa(empresa) {
      const id = empresa ? empresa.id : undefined;

      if (!id) {
        this.value.company = COMPANY_INIT;
      } else {
        this.value = {
          ...this.value,
          company: companyMapper(empresa),
        };
      }
    },
    async getCompanyById(id) {
      const empresa = id
        ? (await pessoasService.findCompanyByIdentificationNumber(id)) || {}
        : {};
      this.fromEmpresa(empresa);
    },

    async fixRegistration() {
      try {
        await socialTransformationService.updateStatusByUserId(
          'draft',
          currentUserId(),
        );
        this.$router.go();
      } catch (error) {
        console.error('fix registration error', error);
      }
    },
  },
  async mounted() {
    const enabled = await settingsService.socialTransformationEnabled();
    if (!enabled) {
      return this.$router.push('/dashboard');
    } else {
      try {
        const user = await pessoasService.findCurrentUser();
        this.value.status =
          user.social_transformation_agent &&
          user.social_transformation_agent.status
            ? user.social_transformation_agent.status
            : 'draft';

        this.$data.value.address = user.endereco;
        if (user.empresa) {
          this.value.company = {
            ...this.value.company,
            partner: partnerMapper({ empresa: user.empresa }),
          };

          await this.getCompanyById(user.empresa.id);
        }
      } catch (error) {
        console.error('load social transformation data error', error);
      } finally {
        this.$forceUpdate();
      }
    }

    this.$data.loading = false;
  },
};
</script>

<template>
  <div class="add-user">
    <card>
      <double-bounce v-if="loading"></double-bounce>
      <h4 slot="header" class="card-title">
        Registro de {{ $t('social_transformation.title') }}
      </h4>

      <div v-if="!loading && value.status === 'draft'">
        <address-form
          title="Preencha seu endereço residencial"
          :initialValue="value.address"
          :error="$v.value.address.$invalid ? 'Informe seu endereço.' : ''"
          @change="handleAddress"
        />

        <company-identification-form
          title="Preencha os dados de identificação de sua empresa"
          :initialValue="value.company.identification"
          :error="
            $v.value.company.identification.$invalid
              ? 'Informe os dados da empresa.'
              : ''
          "
          @change="handleCompanyIdentification"
        />

        <address-form
          v-if="value.company.identification.identificationNumber"
          title="Preencha o endereço de sua empresa"
          :initialValue="value.company.address"
          :error="
            $v.value.company.address.$invalid
              ? 'Informe o endereço da empresa.'
              : ''
          "
          @change="handleCompanyAddress"
        />

        <company-partner-confirmation-form
          title="Termos de Parceria de Serviço"
          :initialValue="value.company.partner"
          :error="
            $v.value.company.partner.$invalid
              ? 'Você precisa estar de acordo.'
              : ''
          "
          @change="handleCompanyPartner"
        />

        <bank-account-information-form
          v-if="value.company.id"
          :initialValue="value.company.bankAccount"
          title="Dados Bancários da Empresa"
          subtitle="Para podermos realizar a remessa dos valores, informe a conta de pessoa jurídica do CNPJ de sua empresa."
          :error="
            $v.value.company.bankAccount.$invalid
              ? 'Informe os dados bancários da empresa.'
              : ''
          "
          @change="handleCompanyBankAccount"
        />
      </div>

      <card v-if="!loading && value.status === 'draft'">
        <h5 slot="header" class="title">Concluir Registro</h5>
        <div style="color: red" v-if="$v.$invalid">
          Informe todos os dados requeridos.
        </div>
        <button v-on:click="send" class="btn btn-sm" :disabled="$v.$invalid">
          enviar o cadastro para análise
        </button>
      </card>
      <card v-if="!loading && value.status === 'review_requested'">
        <h3 slot="header" class="title">
          Seus dados foram enviados para análise.
        </h3>
        <h4 slot="header" class="title">
          Você poderá criar campanhas como
          {{ $t('social_transformation.title') }} apenas após aprovação do
          cadastro
        </h4>
      </card>

      <card
        v-if="!loading && value.status === 'approved'"
        class="alert alert-success"
      >
        <h3 slot="header" class="title">
          Você é um {{ $t('social_transformation.title') }} !
        </h3>
        <h4 slot="header" class="title">
          Seu registro está aprovado e já pode criar campanhas como um Agente
          (API3).
        </h4>
      </card>

      <card v-if="!loading && value.status === 'disapproved'">
        <h3 slot="header" class="title">
          Seu registro não foi aprovado. Verifique seu e-mail ou entre em
          contato com a plataforma.
        </h3>
        <button @click="fixRegistration()" class="btn btn-sm">
          Ir para o registro
        </button>
      </card>
    </card>
  </div>
</template>
