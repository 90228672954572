<script>
import financingService from '@/services/Financing/financingService';
import ordersService from '@/services/Orders/ordersService';

import Vue from 'vue';
import { collectionRef } from '../../firebase';

import { vueTopprogress } from 'vue-top-progress';

import { CWLStatusCampanha } from '@/store/cwl_enums';
import { VTooltip } from 'v-tooltip';

import CampaignCard from '@/components/Campaign/CampaignCard';

import HelperPlugin from '@/plugins/HelperPlugin';
Vue.use(HelperPlugin);

import VueClipboards from 'vue-clipboards';
Vue.use(VueClipboards);

Vue.directive('tooltip', VTooltip);

export default {
  data() {
    return {
      appRootSite: process.env.VUE_APP_ROOT_SITE,
      modalidade: process.env.VUE_APP_TYPE,
      financing: {},
      CWLStatusCampanha: CWLStatusCampanha,
      linkToShare: '',
      showTooltip: false,
    };
  },
  components: {
    vueTopprogress,
    CampaignCard,
  },
  computed: {
    objId() {
      return this.$route.params.id;
    },
    currentPessoa() {
      return this.$store.getters.getPessoa;
    },
  },
  mounted() {
    console.log('MOUNTED!');
    this.financing = this.$CWLNewFinanciamentoPadrao();
    this.get();
  },
  methods: {
    statusDescription(status) {
      return this.$CWLFinancingStatus(status);
    },
    async calculosDaCampanha() {
      const orders = await ordersService.getOrdersByFinancingId(
        this.financing.id,
      );
      return this.$CWLCampanhaCalculosOrdens(this.financing, orders);
    },
    showTooltipHandler() {
      this.showTooltip = true;

      setTimeout(
        function () {
          this.showTooltip = false;
        }.bind(this),
        1000,
      );
    },
    async get() {
      this.$refs.topProgress.start();

      try {
        financingService.get(this.objId).then(
          function (obj) {
            this.financing = obj.data();
            this.financing.id = obj.id;

            this.calculosDaCampanha();
            this.linkToShare =
              this.appRootSite + 'divulgue/' + this.financing.id;

            this.$refs.topProgress.done();
          }.bind(this),
        );
      } catch (error) {
        this.$notify({
          title: 'Ooopss',
          message: 'Desculpe, ocorreu um erro. Tente novamente.',
          icon: 'tim-icons icon-bell-55',
          horizontalAlign: 'center',
          verticalAlign: 'top',
          type: 'warning',
          timeout: 3000,
        });
      }
    },
  },
};
</script>

<template>
  <div class="add-user">
    <card v-if="financing.status == this.CWLStatusCampanha.AguardandoAnalise">
      <div class="alert alert-success" role="alert" style="text-align: center">
        Enviada para análise!
      </div>
      <div class="row justify-content-md-center">
        <div class="col-md-5">
          <campaign-card :financing="financing" />
        </div>
      </div>

      <div class="row">
        <div class="col-md-12">
          <div class="alert alert-success" role="alert" style="opacity: 1">
            <h4 class="alert-heading" style="color: #fff">
              E agora? Quais os próximos passos?
            </h4>
            <p style="color: #fff" v-if="modalidade == 'doacao'">
              Nossa plataforma faz uma
              <span
                v-tooltip="
                  'Curadoria é uma análise que a plataforma faz nas informações que você passou para garantirmos que é uma campanha válida.'
                "
                ><b>curadoria</b></span
              >
              em todas as campanhas antes de serem publicadas.
            </p>
            <p style="color: #fff" v-if="modalidade == 'equity'">
              Nossa plataforma faz uma
              <span
                v-tooltip="
                  'Curadoria é uma análise que a plataforma faz nas informações que você passou para garantirmos que é uma oferta válida.'
                "
                ><b>curadoria</b></span
              >
              em todas as ofertas antes de serem publicadas.
            </p>
            <p style="color: #fff">
              Agora que você enviou para análise, é com a gente! Nossa equipe
              irá revisar para ver se está tudo certo e você será avisado por
              e-mail assim que ela for aprovada.
            </p>
            <p v-if="modalidade == 'doacao'" style="color: #fff">
              Após a aprovação, sua campanha já estará pronta para ser publicada
              e então todos poderão contribuir com a sua causa!
            </p>
            <p v-if="modalidade == 'equity'" style="color: #fff">
              Após a aprovação, sua oferta já estará pronta para ser publicada e
              então todos poderão aportar recursos!
            </p>
            <hr style="border-top-color: #fff" />
            <p class="mb-0" style="color: #fff">
              Não se preocupe, o processo é rápido e mantemos você informado
              pelo e-mail cadastrado.
            </p>
          </div>
        </div>
      </div>
    </card>

    <card v-if="financing.status == this.CWLStatusCampanha.EmAndamento">
      <div
        class="alert alert-success"
        role="alert"
        style="text-align: center"
        v-if="modalidade == 'doacao'"
      >
        Sua campanha foi publicada e agora está visível para todos apoiarem sua
        causa!
      </div>
      <div
        class="alert alert-success"
        role="alert"
        style="text-align: center"
        v-if="modalidade == 'equity'"
      >
        Sua oferta foi publicada e agora está visível para todos investirem!
      </div>
      <div class="row justify-content-md-center">
        <div class="col-md-5">
          <campaign-card :financing="financing" />
        </div>
      </div>

      <div class="row">
        <div class="col-md-12">
          <div class="alert alert-success" role="alert" style="opacity: 1">
            <h4
              class="alert-heading"
              style="color: #fff"
              v-if="modalidade == 'doacao'"
            >
              COMPARTILHE SUA CAMPANHA
            </h4>
            <h4
              class="alert-heading"
              style="color: #fff"
              v-if="modalidade == 'equity'"
            >
              COMPARTILHE SUA OFERTA
            </h4>
            <p v-if="modalidade == 'doacao'" style="color: #fff">
              A sua campanha está pronta para receber doações.
            </p>
            <p v-if="modalidade == 'equity'" style="color: #fff">
              A sua oferta está pronta para receber aportes financeiros.
            </p>
            <p style="color: #fff">
              Use o link abaixo e divulgue a sua causa agora mesmo!
            </p>
            <hr style="border-top-color: #fff" />
            <div class="input-group mb-2">
              <div class="input-group-prepend">
                <div
                  class="input-group-text"
                  style="
                    border: 1px solid #fff;
                    padding: 10px;
                    background-color: #fff;
                    color: #00bf9a;
                  "
                >
                  <i
                    class="fa fa-share-alt"
                    aria-hidden="true"
                    style="color: fff; opacity: 1"
                  ></i>
                </div>
              </div>
              <div class="input-group-prepend">
                <div
                  class="input-group-text"
                  style="
                    border: 1px solid #fff;
                    margin-left: -1px;
                    padding: 10px;
                  "
                >
                  <p class="mb-0" style="color: #fff">{{ linkToShare }}</p>
                </div>
              </div>
              <div class="input-group-prepend">
                <div
                  class="input-group-text"
                  style="
                    border: 1px solid #fff;
                    margin-left: -1px;
                    border-top-right-radius: 0.4285rem;
                    border-bottom-right-radius: 0.4285rem;
                    padding: 10px;
                    background-color: #fff;
                    color: #00bf9a;
                    cursor: pointer;
                  "
                >
                  <p
                    class="mb-0"
                    v-tooltip="{
                      content: 'Copiado!',
                      trigger: 'manual',
                      show: showTooltip,
                    }"
                    v-clipboard="linkToShare"
                    @success="showTooltipHandler"
                  >
                    COPIAR
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </card>

    <vue-topprogress ref="topProgress"></vue-topprogress>
  </div>
</template>
