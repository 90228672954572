import Firebase from 'firebase/app';

import store from '@/store/index';
import { withLog } from '../../logger/with_log';
import firebase from 'firebase/app';

const _ref = () => {
  return firebase.firestore().collection('roles');
};

export default {
  async setNewUserClaim(uid, email, claim) {
    withLog(async () => {
      const setClaimFunction =
        Firebase.functions().httpsCallable('AddUserRole');

      const data = { uid, claim, email };
      console.debug('requestr user claim', data);

      await setClaimFunction(data);
    });
  },

  async getUserClaim(user) {
    console.debug('claim users');
    return withLog(async () => {
      const userRole = (await _ref().doc(user.uid).get()).data();
      console.debug('user role', userRole);
      store.commit('SET_CLAIM', userRole);
      return userRole;
    });
  },

  async getUserClaimByEmail(email) {
    console.debug('claim users');
    return withLog(async () => {
      const claims = await _ref().where('email', '==', email).get();
      return claims;
    });
  },

  async deleteClaim(user) {
    withLog(async () => {
      const User = await _ref()
        .where('email', '==', user.item.email.email)
        .get();

      const collectionId = await User.docs[0].id;

      await _ref().doc(collectionId).delete();
    });
  },
};
