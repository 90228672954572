<script>
import CampaignStatementTable from '@/components/Reports/CampaignStatementTable';
import ordersService from '@/services/Orders/ordersService';

export default {
  components: {
    CampaignStatementTable,
  },
  computed: {
    financingId() {
      return this.$route.params.id;
    },
  },
  data() {
    return {
      orders: [],
      modalidade: process.env.VUE_APP_TYPE,
    };
  },
  mounted() {
    this.getOrders();
  },
  methods: {
    async getOrders() {
      this.orders = [];
      this.orders = await ordersService.getOrdersByFinancingId(
        this.financingId,
      );
    },
  },
};
</script>

<template>
  <div class="annual-report">
    <card>
      <div class="report-filter">
        <h5 v-if="modalidade == 'doacao'">
          <router-link
            to="/reports/campaigns-general"
            tag="i"
            class="fa fa-arrow-left"
          ></router-link>
          Extrato por Campanha
        </h5>
        <h5 v-if="modalidade == 'equity'">
          <router-link
            to="/reports/campaigns-general"
            tag="i"
            class="fa fa-arrow-left"
          ></router-link>
          Extrato por Oferta
        </h5>
      </div>

      <campaign-statement-table :data="orders" @reloadData="getOrders" />
    </card>
  </div>
</template>

<style
  lang="scss"
  scoped
  src="@/assets/sass/black-dashboard/views/_campaign-statement.scss"
></style>
