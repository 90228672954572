<script>
import pessoasService from '@/services/Pessoas/pessoasService';
import authenticationService from '@/services/Authentication/authenticationService';
import { vueTopprogress } from 'vue-top-progress';

import Vue from 'vue';
import HelperPlugin from '@/plugins/HelperPlugin';
Vue.use(HelperPlugin);

import vSelect from 'vue-select';
import 'vue-select/dist/vue-select.css';
Vue.component('v-select', vSelect);

import { PessoaCaracteristica, CWLFirebaseError } from '@/store/cwl_enums';

import claimsService from '@/services/Authentication/claimsService';

import birthDatepicker from 'vue-birth-datepicker';
import Password from 'vue-password-strength-meter';

import logsService from '@/services/Logs/logsService';
import { email } from 'vuelidate/lib/validators';
import { cpf, phone } from '@/validate/customValidators.js';

export default {
  data() {
    return {
      pessoa: {
        telefone: {},
        documento: {},
        email: {},
        dados_complementares: {},
      },
      birthday: '',
      password: '',

      tipos_usuario: [
        PessoaCaracteristica.Gestor,
        PessoaCaracteristica.Curador,
      ],
      tipo_usuario: '',

      email_aprovacao_investidor: true,
      email_aporte_realizado: true,

      Deselect: {
        render: (createElement) => createElement('span', '❌'),
      },

      modalidade: process.env.VUE_APP_TYPE,
      submodalidade: process.env.VUE_APP_SUBTYPE,
    };
  },
  validations: {
    pessoa: {
      email: {
        email: {
          valid: email,
        },
      },
      telefone: {
        telefone: {
          valid: phone,
        },
      },
      documento: {
        numero: {
          valid: cpf,
        },
      },
    },
  },
  computed: {
    currentPessoa() {
      return this.$store.getters.getPessoa;
    },
    role() {
      const value = this.$store.getters.userClaim
        ? this.$store.getters.userClaim.role
        : '';
      return value.toLowerCase();
    },
    isGestor() {
      return this.role == PessoaCaracteristica.Gestor;
    },
  },
  components: {
    vueTopprogress,
    birthDatepicker,
    Password,
  },
  mounted() {
    this.pessoa = this.$CWLNewPessoaPadrao();
  },
  methods: {
    async addNewPessoa() {
      this.$refs.topProgress.start();

      if (this.$v.$invalid) {
        return this.$notify({
          title: 'Informe os dados corretamente!',
          message:
            'Um ou mais dados informados não foram preenchidos corretamente.',
          icon: 'tim-icons icon-bell-55',
          horizontalAlign: 'center',
          verticalAlign: 'top',
          type: 'warning',
          timeout: 7000,
        });
      }

      try {
        const newUser = await authenticationService.createNewUser(
          this.pessoa.email.email,
          this.password,
        );

        const { uid, email } = await newUser.user;

        await claimsService.setNewUserClaim(uid, email, this.tipo_usuario);

        if (this.pessoa.email.email) {
          this.pessoa.email.email = this.pessoa.email.email.toLowerCase();
        }

        this.pessoa.emails = [];
        this.pessoa.emails.push(this.pessoa.email);

        if (this.pessoa.telefone.telefone.length > 0) {
          this.pessoa.telefones = [];
          this.pessoa.telefones.push(this.pessoa.telefone);
        }

        this.pessoa.caracteristicas = [];
        this.$CWLVincularCaracteristicaPessoa(
          this.pessoa,
          this.tipo_usuario,
          false,
        );

        this.pessoa.dados_complementares.birthday = this.birthday;

        const logUser = {
          user: {
            email: this.currentPessoa.email.email,
          },
        };
        const logContent = this.pessoa;
        logsService.createNewLog(
          logUser,
          '',
          'Adicionar Usuário',
          '',
          logContent,
        );

        /**
         * Configurações de e-mail
         */
        if ( !this.pessoa.config_emails ) {
          this.pessoa.config_emails = {};
        }
        this.pessoa.config_emails.email_aprovacao_investidor = this.email_aprovacao_investidor;
        this.pessoa.config_emails.email_aporte_realizado = this.email_aporte_realizado;
        
        await pessoasService.createNewPessoa(this.pessoa);

        await authenticationService.updatePassword(this.pessoa.email.email);

        this.$refs.topProgress.done();

        this.pessoa = this.$CWLNewPessoaPadrao();

        this.$router.push('users');

        this.$notify({
          title: 'Usuário criado com sucesso!',
          icon: 'tim-icons icon-bell-55',
          horizontalAlign: 'center',
          verticalAlign: 'top',
          type: 'success',
          timeout: 3000,
        });
      } catch (error) {
        console.log(error.code);
        if ((error.code = CWLFirebaseError.emailJaExistente)) {
          this.$notify({
            title: 'E-mail já existente',
            message: 'Por favor, tente com outro e-mail.',
            icon: 'tim-icons icon-bell-55',
            horizontalAlign: 'center',
            verticalAlign: 'top',
            type: 'warning',
            timeout: 3000,
          });
        } else {
          this.$notify({
            title: 'Ooopss',
            message: 'Desculpe, ocorreu um erro. Tente novamente.',
            icon: 'tim-icons icon-bell-55',
            horizontalAlign: 'center',
            verticalAlign: 'top',
            type: 'warning',
            timeout: 3000,
          });
        }

        this.$refs.topProgress.done();
      }
    },
  },
};
</script>

<template>
  <div class="add-user">
    <form @submit.prevent="addNewPessoa()">
      <card>
        <h5 slot="header" class="title">
          <router-link to="/users" class="fa fa-arrow-left"></router-link>
          Criar Usuário
        </h5>

        <div class="row">
          <div class="col-md-4 pr-md-1">
            <base-input required label="Tipo de usuário*">
              <v-select
                class="style-chooser"
                :options="tipos_usuario"
                v-model="tipo_usuario"
                :components="{ Deselect }"
              >
                <template #search="{ attributes, events }">
                  <input
                    class="vs__search"
                    :required="!tipo_usuario"
                    v-bind="attributes"
                    v-on="events"
                  />
                </template>
              </v-select>
            </base-input>
          </div>
        </div>

        <div class="row">
          <div class="col-md-4 pr-md-1">
            <base-input
              label="Nome*"
              placeholder="Informe o nome"
              v-model="pessoa.nome"
              required
            ></base-input>
          </div>

          <div class="col-md-4 pr-md-1">
            <div class="form-group">
              <base-input
                label="Celular*"
                v-model="pessoa.telefone.telefone"
                placeholder="Informe o melhor celular"
                v-mask="['(##) ####-####', '(##) #####-####']"
                required
              ></base-input>
              <div
                class="error"
                v-if="
                  pessoa.telefone.telefone != '' &&
                  !$v.pessoa.telefone.telefone.valid
                "
              >
                Informe um telefone com DDD válido.
              </div>
            </div>
          </div>

          <div class="col-md-4 pl-md-1">
            <label for="" class="control-label">Data de Nascimento</label><br />
            <birth-datepicker
              v-model="birthday"
              :valueIsString="true"
              :high="true"
              :hideHeader="true"
              :attachment="'bottom'"
              :delimiter="'/'"
              :placeholder="'Informe a data de nascimento. Ex: 27/12/1993'"
              :locale="[
                'Jan',
                'Fev',
                'Mar',
                'Abr',
                'Mai',
                'Jun',
                'Jul',
                'Ago',
                'Set',
                'Out',
                'Nov',
              ]"
            />
          </div>
        </div>

        <div class="row">
          <div class="col-md-4 pr-md-3">
            <div class="form-group">
              <base-input
                label="CPF*"
                v-model="pessoa.documento.numero"
                placeholder="Informe o seu cpf"
                type="cpf"
                v-mask="['###.###.###-##']"
                required
              ></base-input>
              <div
                class="error"
                v-if="
                  pessoa.documento.numero != '' &&
                  !$v.pessoa.documento.numero.valid
                "
              >
                Informe um CPF válido.
              </div>
            </div>
          </div>

          <div class="col-md-4 pr-md-3">
            <div class="form-group">
              <base-input
                label="E-mail*"
                v-model="pessoa.email.email"
                placeholder="Informe o melhor e-mail"
                type="email"
                required
              ></base-input>
              <div
                class="error"
                v-if="pessoa.email.email != '' && !$v.pessoa.email.email.valid"
              >
                Informe um e-mail válido.
              </div>
            </div>
          </div>

          <div class="col-md-4 pr-md-1">
            <label for="" class="control-label"
              >Senha (mínimo 6 caracteres)*</label
            >
            <password
              v-model="password"
              :secureLength="6"
              :toggle="true"
              :defaultClass="'form-control'"
              required
            />
          </div>
        </div>
      </card>

      <card v-if="modalidade == 'equity' && submodalidade == 'bacen' && ( tipo_usuario == 'gestor' || tipo_usuario == 'curador' )">
        <h5 slot="header" class="title">
          Configurações de e-mail
        </h5>

        <div class="row">
          <div class="col-md-12">
            <base-checkbox
              v-if="tipo_usuario == 'gestor' || tipo_usuario == 'curador'"
              v-model="email_aprovacao_investidor"
            >
              Receber e-mails informativos para aprovação de cadastro de investidores
            </base-checkbox>

            <base-checkbox
              v-if="tipo_usuario == 'gestor'"
              v-model="email_aporte_realizado"
            >
              Receber e-mails informativos de aportes realizados pelos investidores
            </base-checkbox>
          </div>
        </div>
      </card>

      <card>
        <button type="submit" class="btn btn-sm">Adicionar</button>
      </card>
    </form>

    <vue-topprogress ref="topProgress"></vue-topprogress>
  </div>
</template>

<style></style>
